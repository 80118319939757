/**
Global editor-ui-layout improvements
-----
 - body classes, container
*/

/* basic styles */
body {
  font-family: 'SourceSansPro', 'Anjhay', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  line-height: 1.5;
  color: #000;
  background: #fff;
  border-top: 5.5rem solid transparent;
  overflow-x: hidden;
}
/* This was added to fix TT-1939 & TT-1967. Hopefully this should fix all problems with
 horizontal scrolling on mobile devices. If we encounter some scrolling issues in the future, 
 try removing this. */
html {
  overflow-x: hidden;
}

.page-type--article {
  border-top: 3.5rem solid transparent;
}

.page-type--gallery {
  border-top: 3.5rem solid transparent;
}

.page-type--moments {
  @apply bg-grey-300;
}

.page-type--producerProfile {
  @apply bg-grey-300;
}

@screen sm {
  body {
    border-top: 7rem solid transparent;
  }
  .main-container {
    overflow-x: visible;
  }
  .page-type--article {
    border-top: 4.5rem solid transparent;
  }
}

@screen md {
  body {
    border-top: 9.75rem solid transparent;
  }
  .page-type--article {
    border-top: 9.75rem solid transparent;
  }
}

/* Basic editor-ui-layout setting for tailwind 1.x */
iframe {
  display: inline-block;
}

input:focus,
textarea:focus,
button:focus,
select:focus,
a:focus {
  outline: 0;
}

hr {
  border-top-width: inherit;
}

/* Image border removal */
img,
picture,
img::after,
picture::after {
  border-style: none;
  outline: none;
}

/* body has fixed position and overflow hidden to avoid scrolling on background
 - used when modal or menu is open
*/
.body-fixed {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  -webkit-backface-visibility: hidden;
}

/* basic container for content
 - in some resolutions is not centered for more space for skyscraper ads
*/
.container {
  @apply px-4 mx-auto;
  max-width: 480px;
}

/* 0-497px: content: full width (mobile)
 - no class needed
*/

/* 480-767px: content: 480px, left and right space growing (mobile)
 - changed starting to 520px (480px + 2x20px)
*/
@screen xs {
  .container {
    @apply px-0;
  }
}

/* 768-995px: content: full width (tablet)*/
@screen sm {
  .container {
    @apply px-5;
    max-width: none;
  }
}

/* 996-1035px: content: 996, center (0 - 20px on both sides)*/
@screen md {
  .container {
    width: 996px;
  }
}

/* 1036-1185px: content: 996, left space 20px, right space is growing to 170px*/
@media (min-width: 1036px) {
  .container {
    margin-left: 20px;
  }
}

/* 1186-1325px: content: 996, left space growing from 20px to 160px, right space 170px*/
@media (min-width: 1186px) {
  .container {
    margin-left: calc(100% - 996px - 170px);
  }
}

/* 1326-1465px: content: 996, left space 160px, right space growing from 170px*/
@media (min-width: 1326px) {
  .container {
    margin-left: 160px;
  }
}

/* 1466-1615px: content: 996, left space growing from 160px to 310px*/
@media (min-width: 1466px) {
  .container {
    margin-left: calc(100% - 996px - 310px);
  }
}

/* 1616px and more: content: 996, left and right space growing from 310px*/
@media (min-width: 1616px) {
  .container {
    margin-left: auto;
  }
}

/* Footer */
.footer {
  background-color: #1a1a22;
  color: #c6c6c7;
}

.footer-social {
  background-color: #0b0a0f;
}

/*
 Dark background as viewport width
  - used for the Bunte Inside stage on the homepage
 */
.layout-bg-dark {
  position: relative;
}

.layout-bg-dark .bg-white,
.layout-bg-dark .sm\:bg-white,
.layout-bg-dark .red-shape-title::after {
  @apply bg-black;
}

.layout-bg-dark h3,
.layout-bg-dark .channel-link {
  @apply text-white;
}

.layout-bg-dark .channel-link:hover::after {
  @apply bg-white;
}

.layout-bg-dark::before {
  content: '';
  @apply bg-black absolute;
  left: -1rem;
  top: 6rem;
  width: 100vw;
  height: calc(100% - 6rem);
  z-index: -1;
  max-width: 480px;
}

@screen xs {
  .layout-bg-dark {
    @apply text-white;
  }
  .layout-bg-dark::before {
    left: -1.25rem;
    top: 4.25rem;
    width: calc(100% + 2.5rem);
    height: calc(100% - 4.25rem);
    z-index: -1;
    max-width: none;
  }
}

@screen sm {
  .layout-bg-dark {
    @apply text-white;
  }

  .layout-bg-dark::before {
    left: 50%;
    top: 0;
    width: 100vw;
    height: 100%;
    transform: translateX(-50%);
  }
}

/* 1036-1185px: content: 996, left space 20px, right space is growing to 170px*/
@media (min-width: 1036px) {
  .layout-bg-dark::before {
    transform: translateX(calc(-20px - (996px / 2)));
  }
}

/* 1186-1325px: content: 996, left space growing from 20px to 160px, right space 170px*/
@media (min-width: 1186px) {
  .layout-bg-dark::before {
    transform: translateX(calc(-1 * (100vw - 996px - 170px + (996px / 2))));
  }
}

/* 1326-1465px: content: 996, left space 160px, right space growing from 170px*/
@media (min-width: 1326px) {
  .layout-bg-dark::before {
    transform: translateX(calc(-160px - (996px / 2)));
  }
}

/* 1466-1615px: content: 996, left space growing from 160px to 310px*/
@media (min-width: 1466px) {
  .layout-bg-dark::before {
    transform: translateX(calc(-1 * (100vw - 996px - 310px + (996px / 2))));
  }
}

/* 1616px and more: content: 996, left and right space growing from 310px*/
@media (min-width: 1616px) {
  .layout-bg-dark::before {
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Support for multi line titles */
.layout-bg-dark-title > h2 {
  @apply bg-white block;
  margin: 0 -2rem -2rem -1rem;
  padding: 0 1rem 2rem;
}

@screen xs {
  .layout-bg-dark-title > h2 {
    margin: 0 -4rem -2rem -2rem;
    padding: 0 2rem 2rem;
  }
}

@screen sm {
  .layout-bg-dark-title > h2 {
    @apply bg-transparent block;
    margin: 0;
    padding: 0;
  }
}
